<template>
  <div id="login">
    <div class="content">
      <div class="header">DTSUM｜织造数字车间管控系统</div>
      <div class="detail">
        <div class="title">
          <div class="first_row">不止是MES</div>
          <div class="second_row">更是织造行业数字化工厂的新起点</div>
        </div>
        <div class="dialog">
          <Form ref="form" :model="formData">
            <div class="form_title">登 录</div>
            <FormItem prop="user">
              <Input
                type="text"
                v-model="formData.account"
                placeholder="请输入账号"
                @on-enter="handleSubmit('formData')"
              >
                <Icon type="ios-person-outline" slot="prepend"></Icon>
              </Input>
            </FormItem>
            <FormItem prop="password">
              <Input
                type="password"
                v-model="formData.password"
                placeholder="请输入密码"
                @on-enter="handleSubmit('formData')"
              >
                <Icon type="ios-lock-outline" slot="prepend"></Icon>
              </Input>
            </FormItem>
            <FormItem style="text-align:start;">
              <label
                ><input type="checkbox" v-model="freeLogin" />七天免登录</label
              >
            </FormItem>
            <FormItem>
              <Button type="primary" @click="handleSaveCookie();handleSubmit('formData')"
                >登录</Button
              >
            </FormItem>
          </Form>
        </div>
      </div>
    </div>
    <footer class="footer">
      <span class="center"
        >关于三象 | 用户协议 | 用户手册 ©2021 Dtsum All Rights Reserved</span
      >
      <span class="right">DTSUM 1.1 r</span>
    </footer>
  </div>
</template>

<script>
import md5 from 'js-md5'
export default {
  data() {
    return {
      freeLogin: false, // 是否免登录
      isDisabled: false,
      formData: {
        account: "",
        password: ""
      },
      ruleValidate: {
        account: [
          {
            required: true,
            message: "账号不能为空",
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: "密码不能为空",
            trigger: "blur"
          }
        ]
      },
      goToPath: "" // 登录后需要跳转的路由
    };
  },
  mounted() {
    this.getMessageFromCookie();
  },
  methods: {
    // 存储cookie
    handleSaveCookie() {
      if (this.freeLogin === true) {
        this.setCookie("username", this.formData.account, 7);
        this.setCookie("password", this.formData.password, 7);
      } else {
        this.removeCookie("username");
        this.removeCookie("password");
      }
    },
    // 从cookie中获取账户密码
    getMessageFromCookie() {
      this.formData.account = this.getCookie("username");
      this.formData.password = this.getCookie("password");
      if (
        this.getCookie("username") !== "" &&
        this.getCookie("username") !== null &&
        this.getCookie("username") !== undefined
      ) {
        this.freeLogin = true;
      }
    },
    setCookie(name, value, day) {
      // 设置cookie
      var oDate = new Date();
      oDate.setDate(oDate.getDate() + day);
      document.cookie = name + "=" + value + ";expires=" + oDate;
    },
    getCookie(name) {
      // 得到getCookie
      var str = document.cookie;
      var arr = str.split("; "); // 每两条记录之间由一个封号和空格隔开
      for (let i = 0; i < arr.length; i++) {
        var arrOther = arr[i].split("=");
        if (arrOther[0] === name) {
          return arrOther[1];
        }
      }
    },
    removeCookie(name) {
      // 消除name的记录
      this.setCookie(name, 1, -1);
    },
    toIndex() {
      this.$router.push("/");
    },
    handleSubmit() {
      // this.$router.push("/index");
      this.axios({
        method: "post",
        url: "/dtsum/zongtong/organization/LoginController/login",
        data: {
          account: this.formData.account,
          password: md5(this.formData.password)
        }
      })
        .then((res) => {
          if (res.data.success === 1){
          localStorage.setItem("userInfo", JSON.stringify(res.data.body.user)); // 缓存个人信息
          localStorage.setItem(
            "authorityList",
            JSON.stringify(res.data.body.authorityList)
          ); // 缓存菜单栏
          this.$router.push("/index");
          this.$Message.success('登录成功')
          } else {
            this.$Message.error('登录失败')
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
#login {
  .content {
    position: relative;
    width: 100%;
    height: calc(100vh - 40px);
    background: url("../../../static/images/login.png") no-repeat;
    background-size: 100% 100%;
    .header {
      display: flex;
      padding: 20px 40px;
      color: #0d47a1;
      font-size: 20px;
      font-weight: bold;
      .right {
        display: flex;
      }
      .right::before {
        content: "";
        display: block;
        width: 2px;
        height: 20px;
        background: #0d47a1;

        margin: 4px 10px;
      }
    }
    .detail {
      color: #222;
      font-size: 30px;
      .title {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 120px;
        text-align: start;
      }
      .dialog {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 120px;
        width: 400px;
        height: 400px;
        padding: 40px;
        background-color: #fff;
        border-radius: $--radius-standard;
      }
    }
  }
  .footer {
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding-left: 10px;
    .center {
      text-align: center;
    }
    .right {
      float: right;
      margin-right: 40px;
    }
  }
}
</style>
<style lang="scss">
#login {
  .dialog {
    .form_title {
      margin: 30px 0;
      font-weight: 400;
      text-align: center;
    }
    .ivu-form-item {
      margin-bottom: 30px;
    }
    .ivu-btn-primary {
      width: 300px;
      margin-left:50%;
      transform: translateX(-50%);
    }
  }
}
</style>
